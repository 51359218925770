import React, { Component } from "react";
import { connect } from "react-redux";

import Newsbox from "../news/Newsbox";
import Banner from "./BannerOrder";
import { NextYearOffers } from "./NextYearOffers";
import { AppFeatures } from "../apps/Apps";
import appCodeImage from "../images/flips_2025_coupon.png";
import { Link } from "react-router-dom";

class Home extends Component {
  render() {
    return (
      <div>
        {/* <NewsBanner /> */}

        <Banner />

        <div className="container content-container">
          <AppCoupons />

          {/* <GastroFilterBox
            style={{ marginTop: 50 }}
            onSearch={e => {
              this.props.gotoGastro();
            }}
          /> */}

          {/* <CovidText />
          <Covid /> */}

          {/* <Offers /> */}

          {/* <ThisYearOffers /> */}

          {/* <div
            className="offers-container"
            style={{ marginTop: 80, marginBottom: 80 }}
          >
            <h1 className="offers-header">Angebote 2025</h1>
            <div className="offers-subheader">
              Hier können ab Mitte November 2024 alle Angebote der Ausgabe 2025
              eingesehen werden!
            </div>
          </div> */}

          <NextYearOffers />

          {/* <div
            className="offers-container"
            style={{ marginTop: 80, marginBottom: 80 }}
          >
            <h1 className="offers-header">Angebote 2025</h1>
            <div className="offers-subheader">
              Hier können ab Mitte November 2024 alle Angebote der Ausgabe 2025
              eingesehen werden!
            </div>
          </div> */}

          <BookPreview />
          <div style={{ marginBottom: 50 }} />

          {/* <GastroFilterBox
            style={{ marginTop: 50 }}
            onSearch={(e) => {
              this.props.gotoGastro();
            }}
          /> */}
          {/* <Newsbox /> */}

          <div style={{ marginBottom: 50 }} />

          {/* <PopupNewsbox /> */}

          <Newsbox />
          <div style={{ marginBottom: 50 }} />
        </div>
      </div>
    );
  }
}

const BookPreview = () => (
  <div>
    <h1 className="offers-header">flips 2025 zum Durchblättern</h1>
    <div
      className="row"
      style={{
        marginTop: 30,
      }}
    >
      <div
        className="col-lg-8 col-md-6 col-lg-offset-2 col-md-offset-3"
        style={{ backgroundColor1: "#fef7ec" }}
      >
        <div style={{}}>
          {/* <iframe
          width="100%"
          height="300"
          style={{}}
          // src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2020/embed/index.html?bg=transparent&max=80&fs=1&sp=2"
          src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2022/embed/index.html?bg=transparent&max=16&fs=1&sp=2"
          //  src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2019/embed/index.html?bg=transparent&max=80&fs=1&sp=2"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          allowFullScreen="true"
        /> */}

          <iframe
            //            src="https://publish.flyeralarm.digital/flips-gutscheinbuch-2025/"
            src="https://publish.flyeralarm.digital/flips-gutscheinbuch-2025/"
            title="flips Gutscheinbuch 2025"
            width="100%"
            height="400"
            style={{ border: "none" }}
            frameBorder="0"
            allowFullScreen="true"
          ></iframe>

          {/* <iframe
          width="100%"
          height="300"
          style={{}}
          src="https://epaper.bw-h.de/flips/flips-gutscheinbuch2018/embed/index.html?bg=transparent&max=16&fs=1&sp=2"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          allowFullScreen="true"
        /> */}
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    gotoGastro: () => {
      dispatch({ type: "SHOW_GASTRO_FILTER", showFilterModal: true });
      ownProps.router.push({
        pathname: `/angebote/1`,
      });
    },
  };
};

Home = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;

function AppCoupons() {
  return (
    <div className="">
      <h1 className="offers-header">
        Die digitalen Gutscheine
        {/* <br />
        (ab 01.01.2025 aktivierbar)
        <br /> */}
      </h1>
      <div className="row" style={{ fontSize: 18 }}>
        <div className="col-md-6 vcenter">
          <div
            style={{
              marginTop: 26,
              marginBottom: 20,
            }}
          >
            <Link to="/bestellen">
              <img
                role="presentation"
                src={appCodeImage}
                className="img-responsive center-block "
                style={{ maxHeight: 346 }}
              />
            </Link>
          </div>
        </div>

        <div className=" col-md-6 vcenter" style={{ paddingBottom: 20 }}>
          <ul>
            <li>Im regionalen Buchhandel erhältlich</li>
            <li>Kostenpflichtig 18,95 €</li>
            <li>
              Ermöglicht das Einlösen aller im flips Gutscheinbuch
              veröffentlichten Gutscheine ohne Buch
            </li>
            <li>Hat alle Funktionen der Standard App</li>
            <li>Die App kann auf bis zu drei Geräten verwendet werden</li>
            {/* <li>Ab 01.01.2025 aktivierbar</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

function AppCoupons1() {
  return (
    <div className="" style={{ marginTop: 30 }}>
      <div className="row" style={{ fontSize: 18 }}>
        <div className="col-md-6 vcenter">
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Link to="/bestellen">
              <img
                role="presentation"
                src={appCodeImage}
                className="img-responsive center-block "
                style={{ maxHeight: 346 }}
              />
            </Link>
          </div>
        </div>

        <div className=" col-md-6 vcenter" style={{ paddingBottom: 20 }}>
          <h1
            className="offers-header"
            style={{ textAlign: "left", marginBottom: 20, marginLeft: 20 }}
          >
            Die digitalen Gutscheine
            {/* <br />
            (ab 01.01.2025 aktivierbar)
            <br /> */}
          </h1>
          <ul>
            <li>Kostenpflichtig 18,95 €</li>
            <li>
              Ermöglicht das Einlösen aller im flips Gutscheinbuch
              veröffentlichten Gutscheine ohne Buch
            </li>
            <li>Hat alle Funktionen der Standard App</li>
            <li>Die App kann auf bis zu drei Geräten verwendet werden</li>
            {/* <li>Ab 01.01.2025 aktivierbar</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
